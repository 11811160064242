<template>
  <div class="product-page--create">
    <div v-if="loadData" class="d-flex align-center justify-center">
      <Loading />
    </div>
    <template v-else>
      <redirect class="mb-5" title="Upsell offers" route="website.upsell.quantity.list"/>
      <h1 class="page-title">{{ mode === 'create' ? 'Create Offer' : 'Update Offer' }}</h1>
      <Notification type="error" :messages="errors" v-if="errors.length > 0"/>
      <v-row class="mb-8">
        <v-col cols="12" sm="4">
          <h4>Offer's name & message</h4>
        </v-col>
        <v-col cols="12" sm="8" class="elevation-form px-4 pt-4 pb-5">
          <InputDefault
            fieldName="Offer name"
            :isValidate="true"
            label="Offer's name"
            :model="['formData', 'offerName']"
            class="pa-0"
            style="box-shadow: none !important;"
          />
          <p class="input-desc mb-4 mt-0">
            Give your offer a name to manage easily or leave blank and we will automatically generate a name for you
          </p>
          <InputDefault
            :itemsSelect="itemsMessage"
            typeComponent="select"
            label="Offer message"
            :model="['formData', 'offerMessage']"
            class="pa-0"
            style="box-shadow: none !important;"
          />
        </v-col>
        <v-col cols="12" class="my-6">
          <v-divider class=""></v-divider>
        </v-col>
        <v-col cols="12" sm="4">
          <h4>Choose Target products</h4>
          <small>If customers add these products/ products in these collections to cart, this offer will pop up </small>
        </v-col>
        <v-col cols="12" sm="8" class="elevation-form px-4">
          <v-radio-group v-model="formData.targetType" hide-details>
            <v-radio value="all" label="All product"> </v-radio>
            <!-- <v-radio label="Specific products" value="product">
                  <template v-slot:label>
                    <div class="d-flex flex-column pt-20">
                      <span> Specific products </span>
                      <v-btn
                        v-if="formData.targetType === 'product'"
                        @click="configDialogSelection({ type: 'products', model: 'targetIds' })"
                      >
                        Select products</v-btn
                      >
                      <span v-if="formData.targetIds && formData.targetType === 'product'"
                        >{{ formData.targetIds.length }} product selected</span
                      >
                    </div>
                  </template>
                </v-radio>
                <v-radio label=" Specific collections" value="collection">
                  <template v-slot:label>
                    <div class="d-flex flex-column pt-20">
                      <span> Specific collections </span>
                      <v-btn
                        v-if="formData.targetType === 'collection'"
                        @click="configDialogSelection({ type: 'collections', model: 'targetIds' })"
                      >
                        Select Collection</v-btn
                      >
                      <span v-if="formData.targetIds && formData.targetType === 'collection'"
                        >{{ formData.targetIds.length }} Collection selected</span
                      >
                    </div>
                  </template>
                </v-radio> -->
          </v-radio-group>
          <small class="text-error" v-if="formData.targetIds.length === 0 && formData.targetType !== 'all'"
            >Please select product(s)/collection(s).
          </small>
        </v-col>
        <v-col cols="12" class="my-2">
          <v-divider class=""></v-divider>
        </v-col>
        <v-col cols="12" sm="4">
          <h4>Offer’s discount</h4>
          <small>
            Offer discount and scarcity to motivate customers add Up-sell products to cart
          </small>
        </v-col>
        <v-col cols="12" sm="8" class="elevation-form pa-4">
          <v-switch
            v-model="formData.typeQuantity"
            @change="changeType($event, `nextProduct`)"
            :value="`quantity`"
            :label="`Quantity Discounts`"
            class="mt-0 mb-2"
            hide-details
          ></v-switch>
          <div v-if="formData.typeQuantity === 'quantity'" class="my-2">
            <table class="mb-2">
              <thead>
                <th style="width: 100px">Min Quantity</th>
                <th style="width: 100px">Discount</th>
                <td></td>
                <td></td>
              </thead>
              <tbody>
                <tr v-for="(item, key) in formData.discountData" :key="`k${key}`">
                  <td>
                    <v-text-field
                      @input="checkDisccount"
                      type="number"
                      v-model.number="item.minQuantity"
                      hide-details
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="item.valueDiscount" hide-details></v-text-field>
                  </td>
                  <td>
                    <v-select
                      color
                      v-model="item.discountType"
                      :items="typeDiscount"
                      item-text="name"
                      item-value="id"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-btn
                      icon
                      color="error"
                      v-if="formData.discountData.length > 1"
                      @click="formData.discountData.splice(key, 1)"
                    >
                      <v-icon> mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
            <v-btn @click="addDisccount" depressed>Add more +</v-btn>
            <p v-if="isErrorValidateDisccount" class="text-error mt-2">
              You can't have multiple different discounts for the same number of "Min quantity". Please set another one
            </p>
          </div>
          <v-switch
            v-model="formData.typeQuantity"
            @change="changeType($event, `quantity`)"
            :value="`nextProduct`"
            :label="`Next Product Discounts`"
            class="mt-0"
            hide-details
          ></v-switch>
          <div v-if="formData.typeQuantity === 'nextProduct'" class="mt-2">
            <table class="mb-2">
              <thead>
                <th style="width: 100px">Min Quantity</th>
                <th style="width: 100px">Discount</th>
                <td></td>
                <td></td>
              </thead>
              <tbody>
                <tr v-for="(i, k) in formData.discountData" :key="`k${k}`">
                  <td>
                    <v-text-field
                      @input="checkDisccount"
                      type="number"
                      v-model.number="i.minQuantity"
                      hide-details
                    ></v-text-field>
                  </td>
                  <td><v-text-field v-model="i.valueDiscount" hide-details></v-text-field></td>
                  <td>
                    <v-select
                      color
                      v-model="i.discountType"
                      :items="typeNextProduct"
                      item-text="name"
                      item-value="id"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-btn
                      icon
                      color="error"
                      v-if="formData.discountData.length > 1"
                      @click="formData.discountData.splice(k, 1)"
                    >
                      <v-icon> mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
            <v-btn depressed @click="addDisccount">Add more +</v-btn>
            <div class="text-error mt-2" v-if="isErrorValidateDisccount">
              You can't have multiple different discounts for the same number of "Quantity". Please set another one
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-end pr-0 mt-8">
          <v-btn class="mr-3" @click="onCancel" depressed>Discard</v-btn>
          <v-btn :loading="isLoading" :disabled="isDisable" color="primary" @click="onSubmit" depressed>
            {{ mode === 'create' ? 'Create' : 'Save' }}
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog :scrollable="false" v-model="dialog" width="900" content-class="l-dialog">
        <!-- <SelectionItems
          :model="configSelectionItem.model"
          :type="configSelectionItem.type"
          @returnData="getDataSelected"
          :dialog.sync="dialog"
          :dataSelected="configSelectionItem.data"
          :isSuperShow="true"
        /> -->
      </v-dialog>
      <BeforeLeavePage
    /></template>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
// import SelectionItems from '../component/SelectionItems';
import { offerApi } from '@/apis/offers';
import { convertIdsToArrays } from '@/helpers/convertData';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';

export default {
  components: {
    // SelectionItems,
    Loading,
  },
  data() {
    return {
      loadData: false, // là load data khi qua màn Update
      mode: 'create',
      errors: [],
      configSelectionItem: {
        type: 'products',
        model: 'targetIds',
        data: [],
      },
      dialog: false,
      isDisable: null,
      isLoading: null,
      typeTarget: 'all',
      dataDefault: {
        collectionRecommendIds: [],
        collectionTargetIds: [],
        productRecommendIds: [],
        productTargetIds: [],
      },
      itemsMessage: [
        {
          name: 'Buy More Save More!',
          id: 'Buy More Save More!',
        },
        {
          name: 'Enjoy Your Saving! Buy more to save more',
          id: 'Enjoy Your Saving! Buy more to save more',
        },
        {
          name: 'Best deals today! Get more to get a lower price',
          id: 'Best deals today! Get more to get a lower price',
        },
      ],
      typeDiscount: [
        {
          name: '% sale off on each product',
          id: 'percentageEach',
        },
        {
          name: '$ sale off on each product',
          id: 'amountEach',
        },
        {
          name: '$ on each product',
          id: 'amountAll',
        },
      ],
      typeNextProduct: [
        {
          name: '% sale off on next product',
          id: 'percentageNextProduct',
        },
        {
          name: '$ sale off on next product',
          id: 'amountNextProduct',
        },
      ],
      isErrorValidateDisccount: false,
      formData: {
        typeQuantity: 'quantity',
        activated: true,
        offerMessage: 'Buy More Save More!',
        offerType: 'quantity',
        priority: 0,
        offerName: '',
        offerTitle: null,
        recommendIds: [], // product collection ids ,
        recommendType: 'product', //product, collection, sameCollection, relevantCollection
        targetIds: [],
        targetType: 'all', //all, product, collection
        store: '',
        enableDiscount: false,
        percentageDiscount: 0,
        discountData: [
          {
            key: '1',
            minQuantity: 1,
            valueDiscount: 10,
            discountType: 'percentageEach',
          },
          {
            key: '2',
            minQuantity: 2,
            valueDiscount: 20,
            discountType: 'percentageEach',
          },
        ],
      },
    };
  },
  validations: {
    formData: {
      offerName: {
        required,
      },
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.loadData = true;
      this.mode = 'edit';
      try {
        let res = (await offerApi.getById(this.$route.params.id)).data.offer;
        let [collectionRecommendIds, collectionTargetIds, productRecommendIds, productTargetIds] = await Promise.all([
          convertIdsToArrays('collection', res.collectionRecommendIds),
          convertIdsToArrays('collection', res.collectionTargetIds),
          convertIdsToArrays('product', res.productRecommendIds),
          convertIdsToArrays('product', res.productTargetIds),
        ]);
        this.dataDefault = {
          collectionRecommendIds,
          collectionTargetIds,
          productRecommendIds,
          productTargetIds,
        };

        this.formData = {
          activated: res.activated,
          offerMessage: res.offerMessage,
          offerType: res.offerType,
          priority: res.priority,
          offerName: res.offerName,
          offerTitle: res.offerTitle,
          recommendIds: [], // product collection ids ,
          recommendType: res.recommendType, //product, collection, sameCollection, relevantCollection
          targetIds: [],
          typeQuantity: res.typeQuantity ? res.typeQuantity : 'quantity',
          targetType: res.targetType, //all, product, collection
          enableDiscount: res.enableDiscount,
          percentageDiscount: res.percentageDiscount,
          discountData: res.discountData,
        };
        this.defaultFormData = Object.assign({}, res);
        // if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
        //   console.log(this.formData.recommendType, res[`${this.formData.recommendType}RecommendIds`]);
        //   this.formData.recommendIds = res[`${this.formData.recommendType}RecommendIds`];
        //   this.dataDefault[`${this.formData.recommendType}RecommendIds`] = this.dataDefault[
        //     `${this.formData.recommendType}RecommendIds`
        //   ];
        // }
        // if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
        //   this.formData.targetIds = this.formData.targetIds.concat(res[`${this.formData.targetType}TargetIds`]);
        //   this.dataDefault[`${this.formData.targetType}TargetIds`] = this.dataDefault[
        //     `${this.formData.targetType}TargetIds`
        //   ];
        // }
        if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
          this.formData.recommendIds = this.dataDefault[`${this.formData.recommendType}RecommendIds`];
          this.dataDefault[`${this.formData.recommendType}RecommendIds`] = this.dataDefault[
            `${this.formData.recommendType}RecommendIds`
          ];
        }
        if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
          this.formData.targetIds = this.dataDefault[`${this.formData.targetType}TargetIds`];
          this.dataDefault[`${this.formData.targetType}TargetIds`] = this.dataDefault[
            `${this.formData.targetType}TargetIds`
          ];
        }
        this.$forceUpdate();
        this.loadData = false;
      } catch (error) {
        this.loadData = false;
        //empty
      }
      this.componentMount = true;
    }
  },
  methods: {
    changeType(e, value) {
      if (e) {
        this.formData.typeQuantity = e;
        if (e === 'quantity') {
          this.formData.discountData = [
            {
              key: '1',
              minQuantity: 1,
              valueDiscount: 10,
              discountType: 'percentageEach',
            },
            {
              key: '2',
              minQuantity: 2,
              valueDiscount: 20,
              discountType: 'percentageEach',
            },
          ];
        } else {
          this.formData.discountData = [
            {
              key: '1',
              minQuantity: 1,
              valueDiscount: 10,
              discountType: 'percentageNextProduct',
            },
            {
              key: '2',
              minQuantity: 2,
              valueDiscount: 20,
              discountType: 'percentageNextProduct',
            },
          ];
        }
      } else {
        // this.formData.typeQuantity = value;
      }
      this.$forceUpdate();
    },
    checkDisccount() {
      this.isErrorValidateDisccount = false;
      var valueArr = this.formData.discountData.map(function(item) {
        return item.minQuantity;
      });
      this.isErrorValidateDisccount = valueArr.some(function(item, idx) {
        return valueArr.indexOf(item) != idx;
      });
      // for (let i = 0; i < this.formData.discountData.length; i++) {
      //   for (let j = 0; j < this.formData.discountData.length; j++) {
      //     if (i !== j && this.formData.discountData[i].minQuantity === this.formData.discountData[j].minQuantity) {
      //       this.isErrorValidateDisccount = true;
      //     }
      //   }
      // }
    },

    addDisccount() {
      this.formData.discountData.push({
        key: this.formData.discountData.length,
        minQuantity: 1,
        valueDiscount: 10,
        discountType: this.formData.typeQuantity === 'quantity' ? 'percentageEach' : 'percentageNextProduct',
      });
      this.checkDisccount();
    },
    onCancel() {},
    async onSubmit() {
      if (
        (this.formData.targetType === 'all' ||
          (this.formData.targetType !== 'all' && this.formData.targetIds.length > 0)) &&
        !this.isErrorValidateDisccount
      ) {
        let convertData = Object.assign({}, this.formData);
        convertData.targetIds = convertData.targetIds.map(item => item._id);
        // convertData.recommendIds = convertData.recommendIds.map(item => item._id);
        convertData.store = this.$store.state.auth.accountInfo.storeId;
        if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
          convertData[`${this.formData.targetType}TargetIds`] = convertData.targetIds;
        }
        if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
          convertData[`${this.formData.recommendType}RecommendIds`] = convertData.recommendIds;
        }
        delete convertData.recommendIds;
        delete convertData.targetIds;
        if (convertData.offerName.trim() === '') {
          convertData.offerName = 'Target any product';
        }
        try {
          this.isLoading = true;
          this.isDisable = true;
          let response = null;
          if (this.mode === 'edit') {
            response = await offerApi.update(this.$route.params.id, convertData);
            this.isLoading = false;
            this.isDisable = false;
          } else {
            response = await offerApi.create({
              offer: convertData,
            });
            this.$router.push({ name: 'website.upsell.quantity.update', params: { id: response.data.offer._id } });
            this.isDisable = false;
            this.isLoading = false;
          }
        } catch (error) {
          console.log(error);
          //empty
        }
      }
    },
    configDialogSelection(data) {
      this.dialog = true;
      this.configSelectionItem = Object.assign({}, data);
      if (this.formData.targetIds && data.model === 'targetIds') {
        this.configSelectionItem.data = this.formData.targetIds;
      }
      if (this.formData.recommendIds && data.model === 'recommendIds') {
        this.configSelectionItem.data = this.formData.recommendIds;
      }
      // this.configSelectionItem.data = this.dataDefault[
      //   `${data.type.replace('s', '')}${data.model.charAt(0).toUpperCase() + data.model.slice(1)}`
      // ];
    },
    getDataSelected(data, model) {
      this.formData[model] = JSON.parse(JSON.stringify(data));
    },
  },
  watch: {
    'formData.typeQuantity': function() {
      if (this.formData.typeQuantity === null) {
        this.isDisable = true;
      } else {
        this.isDisable = false;
      }
    },
    'formData.discountData': {
      handler: function() {
        this.checkDisccount();
      },
      deep: true,
    },
    'formData.targetType': function(n, o) {
      if (o) {
        this.formData.targetIds = [];
      }
      if (
        (o && this.$route.params.id !== undefined && this.formData.targetType === 'collection') ||
        this.formData.targetType === 'product'
      ) {
        this.formData.targetIds = this.dataDefault[`${this.formData.targetType}TargetIds`];
      }
    },
    'formData.recommendType': function(n, o) {
      if (o) {
        this.formData.recommendIds = [];
      }
      if (
        (o && this.$route.params.id !== undefined && this.formData.recommendType === 'collection') ||
        this.formData.recommendType === 'product'
      ) {
        this.formData.recommendIds = this.dataDefault[`${this.formData.recommendType}RecommendIds`];
      }
    },
  },
};
</script>
